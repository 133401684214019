<template>
  <container-list-extend title="Commande">
    <template v-slot:title>
      <UserViewer :userId="commande.user_id" class></UserViewer>
      <div class="p-1 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <span class="ml-3 text-muted">Commande N°{{ commande.id }}</span>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <description-from-list :value="commande.etat_id" :data="data.etats" text="à saisir"></description-from-list>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <div class="pl-3 pr-3 mt-3">
        <button
          class="btn btn-outline-primary mr-2"
          :class="{ active: commande.etat_id == 1 }"
          @click="updateCommandeEtat(1)"
        >
          Demande
        </button>
        <button
          class="btn btn-outline-primary mr-2"
          :class="{ active: commande.etat_id == 2 }"
          @click="updateCommandeEtat(2)"
        >
          Commande
        </button>
        <button
          class="btn btn-outline-primary mr-2"
          :class="{ active: commande.etat_id == 3 }"
          @click="updateCommandeEtat(3)"
        >
          Livraison
        </button>
        <button
          class="btn btn-outline-primary"
          :class="{ active: commande.etat_id == 4 }"
          @click="updateCommandeEtat(4)"
        >
          Contrôle
        </button>
      </div>
      <div class="p-3">
        <div v-if="commande.etat_id !== 1 && commande.societe_facturation_id" class="text-muted">
          Société :
          <description-from-list
            :value="commande.societe_facturation_id"
            :data="data.societes"
            text="à saisir"
          ></description-from-list>
        </div>
        <div v-if="commande.etat_id !== 1 && commande.contact_livraison" class="text-muted">
          Contact pour la livraison :
          {{ commande.contact_livraison }}
        </div>
        <div v-if="commande.etat_id !== 1 && commande.adresse_livraison" class="text-muted">
          Adresse de livraison :
          {{ commande.adresse_livraison }}
        </div>
        <div v-if="commande.etat_id !== 1 && commande.commentaire" class="text-muted">
          Commentaire du demandeur :
          {{ commande.commentaire }}
        </div>
      </div>
      <div class="mt-3" v-if="commande.etat_id == 2">
        <trame-uploader
          id="document_materiel_commande_trame"
          :file_system="'MATERIELS'"
          title="Exportez le bon de commande"
          :data="BdcData"
          :shadow="false"
          class="m-0 p-0"
        ></trame-uploader>
      </div>
      <div class="mt-3" v-if="commande.etat_id > 2">
        <uploader
          v-if="commande.id"
          id="document_materiel_commande"
          url="/upload/document"
          title="Vos fichiers"
          :file_system="'MATERIEL/commandes'"
          :file_field="'commande'"
          :file_key="commande.id"
          class="p-3 rounded"
        ></uploader>
      </div>
      <div class="d-flex p-3">
        <button class="btn btn-danger" @click="deleteCommande(commande)">Supprimer</button>
        <button class="btn btn-primary ml-auto" v-if="commande.etat_id == 1" @click="updateCommandeEtat(2)">
          valider la demande
        </button>
        <button class="btn btn-primary ml-auto" v-if="commande.etat_id == 2" @click="updateCommandeEtat(3)">
          valider la commande
        </button>
        <button class="btn btn-primary ml-auto" v-if="commande.etat_id == 3" @click="updateCommandeEtat(4)">
          valider la réception
        </button>
      </div>
    </template>
    <template v-slot:actions>
      <CommandesItemArticles :commande="commande"></CommandesItemArticles>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
import { mapGetters } from "vuex";

import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import DescriptionFromList from "@/components/bases/DescriptionFromList";
import CommandesItemArticles from "@/components/materiels/CommandesItemArticles";
import UserViewer from "@/components/application/UserViewer";
import TrameUploader from "@/components/application/TrameUploader.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  name: "CommandesItem",
  components: {
    ContainerListExtend,
    DescriptionFromList,
    CommandesItemArticles,
    UserViewer,
    TrameUploader,
    Uploader,
  },

  data() {
    return {
      feedback: {},
      loading: false,
      statut: null,
    };
  },
  props: ["commande"],
  computed: {
    ...mapGetters({
      data: "materiel/data",
      commandeArticles: "materiel/commandeArticles",
      articles: "materiel/articles",
    }),
    BdcData: function () {
      // DATA
      let data = this.commandeArticles;
      let articles = [];
      let totalHT = 0;

      if (!data) return [];

      data.forEach((cmdArticle) => {
        if (cmdArticle.commande_id == this.commande.id && cmdArticle.quantite_commande > 0) {
          const desc = this.articleById(cmdArticle.article_id).description;
          const prix_unitaire = this.articleById(cmdArticle.article_id).prix_unitaire_ht;
          const quantite = cmdArticle.quantite_commande;
          const prix = cmdArticle.quantite_commande * this.articleById(cmdArticle.article_id).prix_unitaire_ht;
          totalHT = totalHT + prix;
          articles.push([desc, prix_unitaire, quantite, prix.toFixed(2)]);
        }
      });

      return {
        values: {
          num: this.commande.id,
          date: this.$moment().format("DD/MM/YYYY"),
          totalHT: totalHT,
          totalTTC: totalHT * 1.2,
          totalTVA: totalHT * 0.2,
        },
        tablesSize: 9,
        tables: [
          {
            key: "tableau_articles",
            data: {
              header: [
                { id: 1, text: "Description" },
                { id: 1, text: "Prix unitaire" },
                { id: 2, text: "Quantité" },
                { id: 3, text: "Prix" },
              ],
              body: articles,
            },
          },
        ],
        images: [],
      };
    },
  },
  methods: {
    ...mapActions({
      _updateCommande: "materiel/updateCommande",
      _deleteCommande: "materiel/deleteCommande",
    }),
    updateCommande(commande) {
      this.feedback = {};
      this.loading = true;
      this._updateCommande(commande)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteCommande(commande) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteCommande(commande)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    updateCommandeEtat(etat_id) {
      this.commande.etat_id = etat_id;
      this.updateCommande(this.commande);
    },

    articleById(id) {
      return this.articles.filter((art) => art.id == id)[0];
    },
  },
};
</script>
<style lang="css"></style>
