<template>
  <div>
    <div class="">
      <div class="mb-1" :class="qwarkBorder"></div>
      <div
        v-for="cmdArticle in cmdArticlesFiltered"
        :key="cmdArticle.id"
        class="mb-1 p-2 rounded shadow-sm"
        v-background-3
      >
        <div
          class="d-flex justify-content-between align-items-center w-100"
          v-if="cmdArticle.commande_id == commande.id"
        >
          <div class="d-flex align-items-center">
            <AppAvatar
              :url="getArticleImage(cmdArticle.article_id)"
              propSize="4rem"
              :rounded="false"
              class="mr-2"
            ></AppAvatar>

            <div class="d-flex flex-column mr-2">
              <strong>
                <description-from-list
                  v-if="cmdArticle.article_id && articles"
                  :value="cmdArticle.article_id"
                  :data="articles"
                ></description-from-list>
                <small>
                  <div class="text-muted text-nowrap">
                    Prix unitaire : {{ getArticlePrice(cmdArticle.article_id) }} € H.T
                  </div>
                </small>
              </strong>
              <div class="text-nowrap" v-if="commande.etat_id == 2">
                <small>
                  <div>
                    Demande : {{ cmdArticle.quantite }} ({{
                      (getArticlePrice(cmdArticle.article_id) * cmdArticle.quantite).toFixed(2)
                    }}
                    € HT)
                  </div>
                </small>
              </div>
              <div>
                <small>
                  <div class="text-nowrap">
                    Commande : {{ cmdArticle.quantite_commande }} ({{
                      (getArticlePrice(cmdArticle.article_id) * cmdArticle.quantite_commande).toFixed(2)
                    }}
                    € HT)
                  </div>
                </small>
              </div>
            </div>
          </div>

          <div class="d-flex" v-if="commande.etat_id == 2">
            <buttonCircle
              :loading="loading"
              size="24"
              :padding="1.6"
              name="plus-circle"
              colorClass="btn-primary"
              @click="incrementArticle(cmdArticle)"
              v-tooltip="'Ajouter un article'"
            ></buttonCircle>
            <buttonCircle
              :loading="loading"
              size="24"
              :padding="1.6"
              name="minus-circle"
              colorClass="btn-primary"
              @click="decrementArticle(cmdArticle)"
              v-tooltip="'Retirer un article'"
            ></buttonCircle>
            <buttonCircle
              :loading="loading"
              size="24"
              :padding="1.6"
              name="check-circle"
              colorClass="btn-primary"
              @click="transformDemandToCommand(cmdArticle)"
              v-tooltip="'Auto'"
            ></buttonCircle>
          </div>
          <div v-if="!cmdArticle.livraison_date && commande.etat_id == 4" class="d-flex mr-2">
            <ButtonCircle
              :loading="loading"
              size="24"
              :padding="1.6"
              name="check"
              colorClass="btn-primary"
              @click="declareLivraison(cmdArticle)"
              v-tooltip="'Déclarer la livraison'"
            ></ButtonCircle>
          </div>
          <div
            class="d-flex flex-column"
            v-if="cmdArticle.livraison_date && commande.etat_id == 4"
            style="width: 200px"
          >
            <input
              type="range"
              v-model.number="cmdArticle.livraison_quantite"
              :max="cmdArticle.quantite_commande"
              class="custom-range border-0 mr-1"
              @change="updateCommandeArticle(cmdArticle)"
              style="max-width: 100px"
            />
            <span>
              <small
                >quantité livrée : {{ cmdArticle.livraison_quantite }} le
                {{ $moment(cmdArticle.livraison_date).format("DD/MM/YYYY") }}</small
              >
            </span>
          </div>
          <ButtonCircle
            v-if="cmdArticle.livraison_date && commande.etat_id == 4"
            :loading="loading"
            size="24"
            :padding="1.6"
            name="x"
            colorClass="btn-primary"
            @click="deleteDeclarationLivraison(cmdArticle)"
            v-tooltip="'Supprimer la déclaration de livraison'"
          ></ButtonCircle>
        </div>
      </div>
      <div class="d-flex align-items-center mb-5 mt-2">
        <div class="ml-auto">
          <small>
            <div>total demandé : {{ getTotalPriceDemande().toFixed(2) }} € H.T</div>
            <div>total commandé : {{ getTotalPrice().toFixed(2) }} € H.T</div>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
import { mapGetters } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import DescriptionFromList from "@/components/bases/DescriptionFromList";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import AppAvatar from "@/components/bases/Avatar.vue";

export default {
  name: "CommandesItem",
  components: {
    DescriptionFromList,
    ButtonCircle,
    AppAvatar,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null,
      selectedArticle: null,
      quantity: 0,
    };
  },
  props: ["commande"],
  computed: {
    ...mapGetters({
      data: "materiel/data",
    }),
    ...mapMultiRowFields("materiel", ["commandeArticles", "articles", "stocks"]),
    cmdArticlesFiltered: function () {
      return this.commandeArticles.filter((article) => article.commande_id === this.commande.id);
    },
    qwarkBorder: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    },
  },
  methods: {
    ...mapActions({
      _createCommandeArticle: "materiel/createCommandeArticle",
      _updateCommandeArticle: "materiel/updateCommandeArticle",
      _deleteCommandeArticle: "materiel/deleteCommandeArticle",
    }),
    createCommandeArticle: function () {
      this.feedback = {};
      this.loading = true;
      this._createCommandeArticle({
        commande_id: this.commande.id,
        article_id: this.selectedArticle,
        quantite: this.quantity,
      })
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    updateCommandeArticle: function (cmdArticle) {
      this.feedback = {};
      this.loading = true;
      this._updateCommandeArticle({
        id: cmdArticle.id,
        commande_id: this.commande.id,
        article_id: cmdArticle.article_id,
        quantite: cmdArticle.quantite,
        quantite_commande: cmdArticle.quantite_commande,
        livraison_date: cmdArticle.livraison_date,
        livraison_quantite: cmdArticle.livraison_quantite,
      })
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    incrementArticle: function (article) {
      article.quantite_commande++;
      this.updateCommandeArticle(article);
    },
    decrementArticle: function (article) {
      if (article.quantite_commande > 0) {
        article.quantite_commande--;
        this.updateCommandeArticle(article);
      }
    },
    declareLivraison: function (cmdArticle) {
      cmdArticle.livraison_date = this.$moment().format();
      this.updateCommandeArticle(cmdArticle);
    },
    deleteDeclarationLivraison: function (cmdArticle) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      cmdArticle.livraison_date = null;
      cmdArticle.livraison_quantite = 0;
      this.updateCommandeArticle(cmdArticle);
    },
    deleteCommandeArticle: function (data) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteCommandeArticle(data)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    transformDemandToCommand: function (cmdArticle) {
      cmdArticle.quantite_commande = cmdArticle.quantite;
      this.updateCommandeArticle(cmdArticle);
    },
    getArticleCountById: function (id) {
      let cpt = 0;
      this.stocks.forEach((stock) => {
        if (stock.article_id === id) {
          cpt = cpt + 1;
        }
      });
      return cpt;
    },
    getArticleDescription: function (id) {
      let description = "";
      this.articles.forEach((article) => {
        if (article.id === id) {
          description = article.description;
        }
      });
      return description;
    },
    getArticleImage: function (id) {
      let image = "";
      this.articles.forEach((article) => {
        if (article.id === id) {
          image = article.image;
        }
      });
      return image;
    },
    getArticlePrice: function (id) {
      let prix = 0;
      this.articles.forEach((article) => {
        if (article.id === id) {
          prix = article.prix_unitaire_ht;
        }
      });
      return prix;
    },
    getTotalPrice: function () {
      let prix = 0;
      this.commandeArticles.forEach((cmdArticle) => {
        if (cmdArticle.commande_id == this.commande.id)
          prix = prix + this.getArticlePrice(cmdArticle.article_id) * cmdArticle.quantite_commande;
      });
      return prix;
    },
    getTotalPriceDemande: function () {
      let prix = 0;
      this.commandeArticles.forEach((cmdArticle) => {
        if (cmdArticle.commande_id == this.commande.id)
          prix = prix + this.getArticlePrice(cmdArticle.article_id) * cmdArticle.quantite;
      });
      return prix;
    },
  },
};
</script>
<style lang="css"></style>
